const MessageTemplates = Vue.component('MessageTemplates', {
  computed: {
    ...Vuex.mapGetters(['hsm', 'templates', 'editMode'])
  },
  template: `<section class="template-message">
  <SectionHeader
    :title="$t('title')"
    icon="book"
    support-section="administrador-/8eb5QjUUPugzFCKxNAwGQB/plantillas-de-mensajes/gzncJ6CnakwPz2nosdjTuh"
    :custom-text-button="$t('templateHelp')"
  >
    <template v-slot:buttons>
      <SupportLink
        support-path="administrador-/wyPWvKJunzeTwyUof2BLCc/hsm-highly-structured-message-/x9CCTpqXTJoxMSS2iRve8Q"
        :custom-text="$t('hsmHelp')"
      />
    </template>
  </SectionHeader>
  <div class="m-1 pb-1 position-relative">
    <SectionCard
      :title="$t('cardTitles[0]')"
      :data-table="hsm"
      type="hsm"/>
    <SectionCard
      :title="$t('cardTitles[1]')"
      :data-table="templates"
      type="templates" />
    <TemplateEditor v-if="editMode"/>
  </div>
</section>`
});
