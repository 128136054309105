const SectionCard = Vue.component('SectionCard', {
  props: {
    title: {
      type: String,
      default: 'Title section'
    },
    type: {
      type: String,
      default: null
    },
    dataTable: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headings: [
        this.$t('table[0]'),
        this.$t('table[1]'),
        this.$t('table[2]'),
        this.$t('table[3]')
      ],
      labels: {
        more: this.$t('expandText.more'),
        less: this.$t('expandText.less')
      },
      search: '',
      customRow: [],
      confirmDelete: null,
      confirmUpdateTemplates: null,
      updateDescription: '',
      showHSM: true, 
      showTemplates: true,
      copyDepartments: [],
      loading: false,
      updatingHSM: false,
      updatedHSMResult: null
    };
  },
  computed: {
    ...Vuex.mapGetters(['departmentsSelector']),
    filterRow() {
      const activeDeps = this.copyDepartments
        .filter(dep => dep.active)
        .map(({name}) => name);
      return this.customRow.filter(([key, departments]) => {
        if (activeDeps.length > 0) {
          return (
            departments.find(dep => activeDeps.includes(dep)) &&
            new RegExp(this.search, 'ig').test(key)
          );
        } else {
          return new RegExp(this.search, 'ig').test(key);
        }
      });
    },
    indexFix() {
      return {
        zIndex: this.type === 'hsm' ? 11 : 10
      };
    }
  },
  watch: {
    dataTable() {
      this.buildTable();
    }
  },
  mounted() {
    this.buildTable();
    this.copyDepartments = JSON.parse(JSON.stringify(this.departmentsSelector));
  },
  methods: {
    ...Vuex.mapActions([
      'editTemplate', 
      'addTemplate', 
      'removeTemplate', 
      'downloadTemplates', 
      'updateTemplates', 
      'notify',
      'syncHSMTemplates'
    ]),
    sortedBy({order}) {
      const sortable = this.filterRow.sort((elemA, elemB) => {
        const [sortA] = elemA;
        const [sortB] = elemB;
        if (order === 'asc') {
          return sortA.toLowerCase() > sortB.toLowerCase() ? -1 : 1;
        }
        return sortB.toLowerCase() > sortA.toLowerCase() ? -1 : 1;
      });
      this.customRow = sortable;
    },
    buildTable() {
      const rowTable = this.dataTable.map(row => {
        const {
          key,
          department_ids: departments = [],
          canned_response: template,
          resource_id: id
        } = row;
        const labelsDepartment = [];
        if (departments.length === 0) {
          labelsDepartment.push(this.$t('no_department'));
        } else {
          departments.map(dep => {
            const {name} =
              this.departmentsSelector.find(({id}) => id === dep) || {};
            !!name && labelsDepartment.push(name);
          });
        }
        const table = [key, labelsDepartment, template, id];
        return table;
      });
      this.customRow = rowTable;
    },
    normalizeKey(value) {
      const nonAlphaNumeric = /[^a-zA-Z0-9_]/g;
      return value.replace(nonAlphaNumeric, '');
    },
    openEdit(id) {
      const template = this.dataTable.find(
        ({resource_id}) => resource_id === id
      );
      this.editTemplate(template);
    },
    selectToRemove(id) {
      this.confirmDelete = id;
    },
    removingTemplate() {
      this.removeTemplate(this.confirmDelete);
      this.confirmDelete = null;
    },
    cancelRemove() {
      this.confirmDelete = null;
    },
    confirmUpdate() {
      this.confirmUpdateTemplates = null;
    },
    downloadingTemplates() {
      this.downloadTemplates()
    },
    uploadingTemplates() {
      this.$refs.fileInput.click();
    },
    toggleTypeAndVisibilityTemplates(newType) {
      if (this.type === 'templates'){
        this.type = 'hidden_templates'
        this.showTemplates = false
      } else {
        this.type = newType;
        this.showTemplates = true
      }
    },
    toggleTypeAndVisibilityHSM(newType) {
      if (this.type === 'hsm'){
        this.type = 'hidden_hsm'
        this.showHSM = false
      } else {
        this.type = newType;
        this.showHSM = true
      }
    },
    updatingTemplates(key, departments, template) {
      this.loading = true;
      const payload = {
        key,
        departments,
        template
      };
      this.updateTemplates(payload)
        .then((response) => {
          this.loading = false;
          const updateTemplatesList=response['updated']
          const createdTemplatesList=response['created']

          let createdMessage = '',
              updatedMessage = '';

          if (updateTemplatesList.length>0){
            const listItems = updateTemplatesList.map(templateName => `<li>${templateName}</li>`).join('');
            const ulString = `<ul>${listItems}</ul>`;
            updatedMessage =`${this.$tc('uploadModal.descUpdated')}<br><b>${ulString}</b>`;
          }
          
          if (createdTemplatesList.length>0){
            const listItems = createdTemplatesList.map(templateName => `<li>${templateName}</li>`).join('');
            const ulString = `<ul>${listItems}</ul>`;
            createdMessage =`${this.$tc('uploadModal.descCreated')}<br><b>${ulString}</b>`;
          }

          if (updateTemplatesList.length==0 && createdTemplatesList.length==0){
            this.updateDescription =`${this.$tc('uploadModal.noChangues')}`;
          } else {
            this.updateDescription =`${createdMessage}<br>${updatedMessage}`;
            this.notify({
              type: 'success',
              message: this.$tc('uploadNotify.notify.success')
            });
          }

          this.confirmUpdateTemplates = true
        })
        .catch((error) => {
          this.loading = false;
          this.notifyError('uploadNotify.notify.error.update')
        });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file || !file.name.endsWith('.xlsx')) {
        this.notifyError('uploadNotify.notify.error.format');
        return;
      }
    
      this.readFile(file, (rows) => {
        rows = rows.filter(row => row.some(cell => cell !== null && cell !== undefined && cell !== ""));
        if (rows.length <= 1 || rows[0].length !== 3) {
          this.notifyError('uploadNotify.notify.error.lengthColumns');
          return;
        }
    
        const keyColumValues = rows.slice(1).map(row => this.normalizeKey(String(row[0])));
        if (!this.isValidKeyColumn(keyColumValues)) {
          return; 
        }
    
        const departmentsColumnValues = rows.slice(1).map(row => {
          const departmentsCell = row[1];
          return departmentsCell ? departmentsCell.split(',').map(dep => dep.trim()) : [];
        });

        const validDepartmentNames = this.copyDepartments.map(({name}) => name);

        for (const departmentRow of departmentsColumnValues) {
          for (const department of departmentRow) {        
            if (department && !validDepartmentNames.includes(department)) {
              this.notifyError('uploadNotify.notify.error.notValidDepartments', { department });
              return
            }
          }
        }

        const templatesColumnValues = rows.slice(1).map(row => String(row[2]));
        if (!this.isValidTemplateColumn(templatesColumnValues)) {
          return;
        }
    
        if (keyColumValues.length > 0) {
          this.updatingTemplates(keyColumValues, departmentsColumnValues, templatesColumnValues);
        }
      });
    },
    
    readFile(file, callback) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        callback(rows);
      };
      reader.onerror = () => this.notifyError('uploadNotify.notify.error.read');
      reader.readAsBinaryString(file);
      this.$refs.fileInput.value = null;
    },
    
    isValidKeyColumn(keyColumValues) {
      if (keyColumValues.some(value => !value || value === 'undefined')) {
        this.notifyError('uploadNotify.notify.error.emptyKey');
        return false;
      }
    
      const uniqueKeyValues = new Set(keyColumValues);
      if (uniqueKeyValues.size !== keyColumValues.length) {
        this.notifyError('uploadNotify.notify.error.duplicateKeys');
        return false;
      }
    
      return true;
    },
    
    isValidTemplateColumn(templatesColumnValues) {
      if (templatesColumnValues.some(value => !value)) {
        this.notifyError('uploadNotify.notify.error.emptyTemplate');
        return false;
      }
    
      return templatesColumnValues.every(template => this.isValidTemplate(template));
    },
    
    isValidTemplate(template) {
      const validPlaceholders = [
        "case_id",
        "name",
        "uname",
        "author.uid",
        "author.name",
        "author.uname",
        "case.id",
        "case.identifier",
        "case.sn",
        "case.account_id",
        "current_assignment.name",
        "utcnow",
        "utcnow.timestamp",
      ]
      const validParameters = validPlaceholders.map(placeholder => `{{${placeholder}}}`);
      const regex = /{{[\w\s]+}}/g;
      let match;
      while ((match = regex.exec(template)) !== null) {
        if (!validParameters.includes(match[0])) {
          this.notifyError('uploadNotify.notify.error.notValidParameters');
          return false;
        }
      }
      return true;
    },
    
    notifyError(messageKey, interpolations={}) {
      this.notify({
        type: 'error',
        message: this.$tc(messageKey, 0, interpolations)
      });
    },   
    addHSM(){
      const link = "https://docs.google.com/forms/d/1ZOSUpFFB5v_vtf78Qn0ztNhyQB_bof8nxDcaopA7FFY/edit"
      window.open(link, "_blank");
    },
    updateHSM(){
      this.updatingHSM = true;
      this.loading = true
      this.syncHSMTemplates().
      then(response => {
        this.updatedHSMResult = response
        this.loading = false
      }).catch(() =>{
        this.notifyError('syncHSM.notify.error');
        this.updatingHSM = false
        this.loading = false
      })
    },
    closeUpdateHSMModal() {
      this.updatingHSM = false
      this.updatedHSMResult = null
      this.loading = false
    }
  },
  template: `<section class="section-card card">
  <header class="section-card__header mb-2">
    <div class="d-flex space-between mb-2">
      <div style="display: flex">
        <button class="btn btn-toggle ads-button--icon" @click="toggleTypeAndVisibilityHSM('hsm')" v-if="type != 'templates' && type != 'hidden_templates'">
          {{ showHSM ? '🔼' :  '🔽' }}
        </button>
        <button class="btn btn-toggle ads-button--icon" @click="toggleTypeAndVisibilityTemplates('templates')" v-if="type != 'hsm' && type != 'hidden_hsm'">
          {{ showTemplates ? '🔼' :  '🔽' }}
        </button>
        <h4>{{ title }}</h4>
      </div>
      <div class="section-card__header--filters d-flex">
        <InputText v-if="type != 'hidden_hsm' && type != 'hidden_templates'"
          class="input-search"
          type="search"
          v-model="search"
          :placeholder="$t('search')" />
        <DepartmentsFilter
        v-if="copyDepartments.length > 0 && type != 'hidden_hsm' && type != 'hidden_templates'"
          :departments="copyDepartments"
          class="ml-1"
          :style="indexFix"/>
        <button
          class="btn ads-button ads-button--primary ml-1"
          @click="addTemplate" v-if="type === 'templates'">
            {{ $t('add') }}
        </button>
        <button
          class="btn ads-button ads-button--primary ml-1"
          @click="downloadingTemplates" v-if="type === 'templates'">
            {{ $t('download') }}
        </button>
        <button
          class="btn ads-button ads-button--primary ml-1"
          @click="uploadingTemplates"
          :disabled="loading"
          v-if="type === 'templates'">
            {{ loading ? $t('loading') : $t('uploadTemplates') }}
        </button>
        <button
          class="btn ads-button ads-button--primary-variant ml-1"
          @click="updateHSM"
          :disabled="loading"
          v-if="type === 'hsm'">
            {{ $t('updateHSM') }}
        </button>
        <button
          class="btn ads-button ads-button--primary ml-1"
          @click="addHSM"
          :disabled="loading"
          v-if="type === 'hsm'">
            {{ $t('addHSM') }}
        </button>
      </div>
    </div>
    <p v-if="type === 'templates'">{{ $t('templateDescription') }}</p>
    <p v-else>{{ $t('hsmDescription') }}</p>
  </header>
  <input
      type="file"
      ref="fileInput"
      accept=".xlsx"
      @change="handleFileUpload"
      style="display: none;"
    />
  <div class="section-card__content" v-if="type === 'hsm'">
      <slot v-if="filterRow.length">
        <BasicTable
          :headings="headings"
          :sorter-callback="sortedBy"
          :sorterFilter="[headings[0]]"
          :custom="true"
          :striped="true"
          class="template-message-table">
          <tr v-for="row in filterRow">
            <slot v-for="(elem, index) in row">
              <td :width="index === 2 ? '60%' : '20%'">
              <span v-if="index === 0" class="alias">{{ elem }}</span>
              <TagsTeam v-if="index === 1" :tags="elem" />
              <ExpandableText
                v-if="index === 2"
                :paragraph="elem"
                :labels={...labels}
                :maxHeight="100"
                :maxLength="80" />
              <slot v-if="index === 3">
                <button class="btn ads-button--icon btn-delete" @click="selectToRemove(elem)">
                  🗑️
                </button>
                <button class="btn ads-button--icon" @click="openEdit(elem)">
                  ✏️
                </button>
              </slot>

              </td>
            </slot>
          </tr>
        </BasicTable>
      </slot>
      <slot v-else>
        <EmptyResults image="images/without_templates.svg" />
      </slot>

  </div>
  <div class="section-card__content" v-if="type === 'templates'">
      <slot v-if="filterRow.length">
        <BasicTable
          :headings="headings"
          :sorter-callback="sortedBy"
          :sorterFilter="[headings[0]]"
          :custom="true"
          :striped="true"
          class="template-message-table">
          <tr v-for="row in filterRow">
            <slot v-for="(elem, index) in row">
              <td :width="index === 2 ? '60%' : '20%'">
              <span v-if="index === 0" class="alias">{{ elem }}</span>
              <TagsTeam v-if="index === 1" :tags="elem" />
              <ExpandableText
                v-if="index === 2"
                :paragraph="elem"
                :labels={...labels}
                :maxHeight="100"
                :maxLength="80" />
              <slot v-if="index === 3">
                <button class="btn ads-button--icon btn-delete" @click="selectToRemove(elem)">
                  🗑️
                </button>
                <button class="btn ads-button--icon" @click="openEdit(elem)">
                  ✏️
                </button>
              </slot>

              </td>
            </slot>
          </tr>
        </BasicTable>
      </slot>
      <slot v-else>
        <EmptyResults image="images/without_templates.svg" />
      </slot>

  </div>
  <footer class="section-card__footer mt-1" v-if="type === 'templates'">
    <button
      class="btn ads-button ads-button--primary"
      @click="addTemplate" v-if="type === 'templates'">
      {{ $t('add') }}
    </button>
  </footer>
  <ConfirmModal
    :open="!!confirmDelete"
    :onConfirm="removingTemplate"
    :onCancel="cancelRemove"
    :title="$t('remove.title')"
    :description="$t('remove.desc')"
  />
  <ConfirmModal
    :open="!!confirmUpdateTemplates"
    :onConfirm="confirmUpdate"
    :title="$t('uploadModal.title')"
    :description="updateDescription"
  />
  <WhatsappUpdatedTemplatesModal
    :open="updatingHSM"
    :loading="loading"
    :title="$t('uploadModal.title')"
    :updatedResults="updatedHSMResult"
    :onConfirm=" () => {updatingHSM = false}"
  />
  </section>`
});
