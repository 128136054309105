interface CannedResponse {
  account_uid?: string
  canned_response: string
  department_ids: number[]
  is_template: boolean
  key: string
  resource_id: string
  template_id?: string
}

interface Hsm extends CannedResponse {
  is_template: true
  template_id: string
}

type CannedResponses = {templates: CannedResponse[], hsm: Hsm[]}

interface WhatsappCampaign {
  id: number
  status: string
  visual_name: string
  created: string
}

interface Pagination {
  page_size: number,
  current_page: number,
  total_pages: number
  next_page: number | null
}


interface HSMTemplateSyncResult {
  template_name: string
  template_status: string
  response_status: number
  message: string
  template_id: string
}

interface HSMChannelSyncResult {
  phone_number: string
  visual_name: string
  templates: HSMTemplateSyncResult[]
}


type WhatsappCampaignsResponse = {result: Array<WhatsappCampaign>, pagination: Pagination}


type WhatsappCampaignResponse = {campaign_id: number, message: string}

class MessageTemplateService {
  private AdRequest: AdRequestService
  private UserService: UserService
  private delimeter: string
  private templatesModel: AdModelFetcher<CannedResponses>

  constructor(AdRequest, UserService) {
    this.AdRequest = AdRequest
    this.UserService = UserService
    this.delimeter = '/'
    this.templatesModel = new AdModelFetcher(() => this.fetchCannedResponses())
  }

  getHsms(): Promise<Hsm[]> {
    return this.templatesModel.get().then(r => r.hsm)
  }
  
  getTemplates(): Promise<CannedResponse[]> {
    return this.templatesModel.get().then(r => r.templates)
  }

  private async fetchCannedResponses(): Promise<CannedResponses> {
    const user = await this.UserService.getProfile()
    const params = new URLSearchParams()
    const response = await this.AdRequest.get<CannedResponse[]>(
      `/api/v1/canned_responses/?${params.toString()}`
    )
    if (user.isSupervisor) {
      const departments = user.profile.departments;
      return {
        templates: this.filterTemplates(response).filter(
          ({department_ids}) => !department_ids.length || department_ids.some(
            id => departments.includes(id)
          )
        ),
        hsm: this.filterTemplates(response, true).filter(
          ({department_ids}) => !department_ids.length || department_ids.some(
            id => departments.includes(id)
          )
        )
      }
    } else {
      return {
        templates: this.filterTemplates(response),
        hsm: this.filterTemplates(response, true)
      }
    }
  }

  async getCannedResponse(): Promise<CannedResponses> {
    return this.templatesModel.refresh()
  }

  createTemplate({key, canned_response, department_ids}) {
    return this.AdRequest.post('/api/v1/canned_responses/', {
      key,
      canned_response,
      department_ids
    })
  }

  updateTemplate(id, {key, canned_response, department_ids}) {
    return this.AdRequest.put(`/api/v1/canned_response/${id}/`, {
      key,
      canned_response,
      department_ids
    })
  }

  removeTemplate(id) {
    return this.AdRequest.delete(`/api/v1/canned_response/${id}/`);
  }

  downloadTemplate() {
    const token = this.AdRequest.getToken();
    const params = this.AdRequest.getParams()
    const url = `${params.baseURL}/api/v1/canned_response_export/`;
    const headers = this.getHeaders(token);
  
    return fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob();
    })
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'templates.xlsx'; 
      document.body.appendChild(a); 
      a.click(); 
      a.remove();
      window.URL.revokeObjectURL(url); 
    });
  }
  
  getHeaders(authToken: string): Headers{
    return new Headers({
      'Authorization': 'Token ' + authToken, 
      'Content-Type': 'application/json; charset=UTF-8',
    })
  }

  updateTemplates(key: string[], departments: string[][], template: string[]) {
    return this.AdRequest.put(`/api/v1/canned_response_import/`, {
      key,
      departments,
      template
    }).then(response => response); 
  }

  syncHSMTemplates(): HSMChannelSyncResult[] {
    return this.AdRequest.put(
      `/api/v1/whatsapp/sync_templates/`
    ).then(response => response["results"] as HSMChannelSyncResult[] )
  }
  
  filterTemplates(templates, hsm = false) {
    return templates
      .filter(({is_template}) => is_template === hsm)
      .map(({department_ids = [], key, ...options}) => {
        const pattern = [this.delimeter, key].join('');
        return {
          department_ids,
          pattern,
          key,
          regex: new RegExp(pattern, ['g']),
          ...options
        };
      });
  }

  getCampaigns(page: number): Promise<WhatsappCampaignsResponse>{
    return this.AdRequest.get(`/api/v1/whatsapp/campaigns/?page=${page}`)
  }

  createCampaign(key: string, account_uid: string, visualName: string, records: Array<Array<string>>): Promise<WhatsappCampaignResponse> {
    return this.AdRequest.post('/api/v1/whatsapp/campaigns/', {
      key,
      account_uid,
      records,
      visual_name: visualName.replace('.csv', '').normalize("NFD").replace(/\p{Diacritic}/gu, ""),
    })
  }

  sendCampaign(campaignId: number, closeTicket: boolean = false) {
    return this.AdRequest.post(`/api/v1/whatsapp/campaigns/${campaignId}/send/`, {
      'close_ticket': closeTicket
    });
  }

  getCampaignDetails(campaignId: number) {
    return this.AdRequest.get(`/api/v1/whatsapp/campaigns/${campaignId}/detail/`)
  }
}
angular
  .module('postCenterWebClientApp')
  .service('MessageTemplateService', MessageTemplateService);
