const messageTemplatesMessage = {
  es: {
    title: 'Plantillas de mensajes y HSM',
    table: ['Alias', 'Departamentos', 'Plantilla', 'Acciones'],
    no_department: 'Sin departamento',
    add: 'Agregar',
    loading: 'Cargando...',
    download: 'Descargar plantillas',
    uploadTemplates: 'Subir plantillas',
    addHSM: 'Agregar HSM',
    updateHSM: 'Actualizar HSM',
    search: 'Buscar',
    hsmHelp: "Mira la ayuda para HSM",
    templateHelp: "Mira la ayuda para Plantillas de Mensajes",
    templateDescription: "Las plantillas son mensajes predefinidos que puedes almacenar y luego enviar mediante un atajo dentro de cada ticket.",
    hsmDescription: "Los HSM o mensajes proactivos son plantillas pre-aprobadas que pueden ser enviados a tus clientes de forma proactiva, es decir, sin que haya existido un mensaje previo de parte del cliente en Adereso.",
    cardTitles: ['HSM', 'Plantillas de mensaje'],
    remove: {
      title: 'Eliminar plantilla',
      desc: 'Realmente desea eliminar esta plantilla'
    },
    uploadModal: {
      title: 'Plantillas actualizadas',
      descUpdated: 'Se han actualizado las siguientes plantillas:',
      descCreated: 'Se han creado las siguientes plantillas:',
      noChangues: 'No se han detectado cambios nuevos',
    },
    uploadNotify: {
      notify: {
        success: '¡Plantillas actualizadas correctamente!',
        error: {
          update: 'Error al actualizar las plantillas',
          duplicateKeys: 'Se encontraron nombres de plantillas repetidos',
          emptyKey: 'No se puede subir una plantilla sin nombre',
          emptyTemplate: 'No se puede subir una plantilla sin texto asociado',
          notValidParameters: 'Alguno de los parametros ingresados en "Template" no son correctos',
          lengthColumns: 'El archivo debe tener 3 columnas y al menos una plantilla',
          read: 'Error al leer el archivo',
          format: 'El archivo debe tener formato .xlsx',
          notValidDepartments: 'El departamento {department} no existe'
        }
      }
    },
    expandText: {
      more: 'Ver todo',
      less: 'Ver menos'
    },
    editor: {
      title: ['Crear plantilla de mensajes', 'Editar plantilla de mensajes'],
      shortcut: 'Alias',
      accountName: 'Nombre de cliente',
      customerAccount: 'Cuenta de cliente',
      currentAssignationName: 'Nombre de agente',
      shortcutDesc:
        'Este es el atajo para gatillar la plantilla en los mensajes, Se utiliza /Bienvenido al responder un mensaje',
      dep: 'Departamento',
      depDesc:
        'Selecciona los departamentos en los que deseas que se active la plantilla de mensajes.',
      templateText: 'Texto de plantilla',
      templateTextDesc:
        'este es el texto de la plantilla, puedes agregar datos pre calculados',
      maxLength: 'Has superado el limite de caracteres.',
      notify: {
        success:
          '¡Plantilla /{key} ha sido creada! | ¡Plantilla /{key} ha sido actualizada!',
        error:
          'Lo sentimos 😢, Por alguna razón no hemos podido crear esta plantilla | Lo sentimos 😢, Por alguna razón no hemos podido editar esta plantilla'
      }
    },
    syncHSM: {
      notify: {
        error: 'Lo sentimos, Por alguna razón no hemos podido sincronizar las plantillas',
        success: 'Plantillas sincronizadas correctamente'
      }
    }
  },
  pt: {
    title: 'Modelos de mensagens e HSM',
    table: ['Apelido', 'Departamentos', 'Modelo', 'Ações'],
    no_department: 'Sem departamento',
    add: 'Adicionar',
    download: 'Baixar modelos',
    loading: 'Loading...',
    uploadTemplates: 'Subir modelos',
    addHSM: 'Adicionar HSM',
    updateHSM: 'Atualizar HSM',
    search: 'Buscar',
    hsmHelp: "Veja a ajuda para HSM",
    templateHelp: "Veja a ajuda para Modelos de Mensagens",
    templateDescription: "As plantillas são mensagens predefinidas que você pode armazenar e depois enviar por meio de um atalho dentro de cada ticket.",
    hsmDescription: "HSMs ou mensagens proativas são modelos pré-aprovados que podem ser enviados proativamente aos seus clientes, ou seja, sem que haja uma mensagem anterior do cliente no Adereso.",
    cardTitles: ['HSM', 'Modelos de mensagem'],
    remove: {
      title: 'Excluir modelo',
      desc: 'Você realmente deseja excluir este modelo'
    },
    uploadModal: {
      title: 'Modelos Atualizados',
      descUpdated: 'Os seguintes modelos foram atualizados:',
      descCreated: 'Os seguintes modelos foram criados:',
      noChangues: 'Não foram detectadas novas alterações',
    },

    uploadNotify: {
      notify: {
        success: 'Modelos atualizados com sucesso!',
        error: {
          update: 'Erro ao atualizar os modelos',
          duplicateKeys: 'Nomes de modelos duplicados encontrados',
          emptyKey: 'Não é possível carregar um modelo sem nome',
          emptyTemplate: 'Não é possível carregar um modelo sem texto associado',
          notValidParameters: 'Alguns dos parâmetros inseridos em "Modelo" não são corretos',
          lengthColumns: 'O arquivo deve ter 3 colunas e pelo menos um modelo',
          read: 'Erro ao ler o arquivo',
          format: 'O arquivo deve estar no formato .xlsx',
          notValidDepartments: 'O departamento {department} não existe'
        }
      }
    },
    expandText: {
      more: 'Ver tudo',
      less: 'Ver menos'
    },
    editor: {
      title: ['Criar modelo de mensagem', 'Editar modelo de mensagem'],
      shortcut: 'Apelido',
      accountName: 'Nome da conta',
      customerAccount: 'Conta de cliente',
      currentAssignationName: 'Nome do agente',
      shortcutDesc:
        'Este é o atalho para acionar o modelo nas mensagens, /Welcome é usado ao responder a uma mensagem',
      dep: 'Departamento',
      depDesc:
        'Selecione os departamentos nos quais deseja que o modelo de mensagem seja ativado.',
      templateText: 'Texto do modelo',
      templateTextDesc:
        'Este é o texto do modelo, você pode adicionar dados pré-calculados',
      maxLength: 'Você excedeu o limite de caracteres.',
      notify: {
        success:
          'O modelo /{key} foi criado! | O modelo /{key} foi atualizado!',
        error:
          'Desculpe 😢, por algum motivo, não foi possível criar este modelo | Desculpe 😢, Por algum motivo, não foi possível editar este modelo'
      }
    },
    syncHSM: {
      notify: {
        error: "Lamentamos, por algum motivo não conseguimos sincronizar os modelos",
        success: 'Modelos sincronizados com sucesso'
      }
    }
  },
  en: {
    title: 'Message templates and HSM',
    table: ['Alias', 'Departments', 'Template', 'Actions'],
    no_department: 'No department',
    add: 'Add',
    loading: 'Carregando...',
    download: 'Download templates',
    uploadTemplates: 'Upload templates',
    addHSM: 'Add HSM',
    updateHSM: "Update HSM",
    search: 'Search',
    hsmHelp: "Check HSM help",
    templateHelp: "Check Message Templates help",
    templateDescription: "Templates are predefined messages that you can store and later send through a shortcut within each ticket.",
    hsmDescription: "HSMs or proactive messages are pre-approved templates that can be sent to your clients proactively, meaning without any prior message from the client in Adereso.",
    cardTitles: ['HSM', 'Message templates'],
    remove: {
      title: 'Delete template',
      desc: 'You really want to delete this template'
    },
    uploadModal: {
      title: 'Templates Updated',
      descUpdated: 'The following templates have been updated:',
      descCreated: 'The following templates have been created:',
      noChangues: 'No new changes have been detected',
    },

    uploadNotify: {
      notify: {
        success: 'Templates successfully updated!',
        error: {
          update: 'Error updating the templates',
          duplicateKeys: 'Duplicate template names found',
          emptyKey: 'Cannot upload a template without a name',
          emptyTemplate: 'Cannot upload a template without associated text',
          notValidParameters: 'Some of the parameters entered in "Template" are not correct',
          lengthColumns: 'The file must have 3 columns and at least one template',
          read: 'Error reading the file',
          format: 'The file must be in .xlsx format',
          notValidDepartments: 'The department {department} does not exist'
        }
      }
    },
    expandText: {
      more: 'Show all',
      less: 'Show less'
    },
    editor: {
      title: ['Create message template', 'Edit message template'],
      shortcut: 'Alias',
      accountName: 'Account name',
      customerAccount: 'Customer account',
      currentAssignationName: 'Agent name',
      shortcutDesc:
        'This is the shortcut to trigger the template in the messages, It is used /Welcome when replying to a message',
      dep: 'DepartmentService',
      depDesc:
        'Select the departments in which you want the message template to be activated.',
      templateText: 'Template text',
      templateTextDesc:
        'This is the template text, you can add pre-calculated data',
      maxLength: 'You have exceeded the character limit.',
      notify: {
        success:
          'The /{key} template has been created! | The /{key} template has been updated!',
        error:
          'Sorry 😢, for some reason, it was not possible to create this template| Sorry 😢, For some reason we were unable to edit this template'
      }
    },
    syncHSM: {
      notify: {
        error: "We're sorry, for some reason we couldn't synchronize the templates",
        success: 'Templates synchronized successfully'
      }
    }
  }
};
