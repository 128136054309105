'use strict';

class AdSidebarCtrl {
  $scope;
  $rootScope;
  navigationColumns;
  $timeout;
  UserService: UserService;
  StylesService: StylesService;
  AdStore: AdStore;
  ColumnsService: ColumnsService;
  CaseDetailManager: CaseDetailManager;
  AdModal;
  $filter;
  EstablishmentService: EstablishmentService;
  FireTagService;
  VersionService: VersionService;
  AdRequest: AdRequestService;
  FRONTEND_VUE_URL

  styles: Styles;
  isDividedView: Boolean;
  navbarExpanded: Boolean;
  toDestroy: {[key: string]: () => void } = {};
  dividedViewSubscription: rxjs.Subscription;

  defaultKeyDownEventsHandler = (e) => {
    if (e.key === "i" && e.ctrlKey) {
      e.preventDefault();
      this.toggleSearch();
    } else if (e.key == "Escape") {
      this.closeSecondaryNavbar();
    };
  };

  closeSecondaryNavbar() {
    document.onclick = null;
    document.onkeydown = this.defaultKeyDownEventsHandler;
    this.$scope.showLateralColumns.open = false;
    this.$scope.showSettings.open = false;
    this.$scope.showSearch.open = false;
    this.$scope.showNewTicket.open = false;
    this.$scope.showHelp.open = false;
    this.$scope.$applyAsync();
  }

  toggleSettings () {
    if (!this.$scope.showSettings.open) {
      this.closeSecondaryNavbar();
      this.$scope.showSettings.open = true;
      this.subscribeToClicks();
    } else {
      this.closeSecondaryNavbar();
    }
  }

  toggleHelp () {
    if (!this.$scope.showHelp.open) {
      this.closeSecondaryNavbar();
      this.$scope.showHelp.open = true;
      this.subscribeToClicks();
    } else {
      this.closeSecondaryNavbar();
    }
  }

  toggleColumns () {
    if (!this.$scope.showLateralColumns.open) {
      this.closeSecondaryNavbar();
      this.$scope.showLateralColumns.open = true;
      this.subscribeToClicks();
    } else {
      this.closeSecondaryNavbar();
    }
  }

  searchModal () {
    this.$rootScope.$emit('modalSearch', {});
  }

  /* OLD SEARCH */

  toggleSearch () {
    if (!this.$scope.showSearch.open) {
      this.closeSecondaryNavbar();
      this.$scope.showSearch.open = true;
      this.subscribeToClicks();
      this.focusInput();
      this.subscribeToKeyDown();
    } else {
      this.closeSecondaryNavbar();
    }
    this.$scope.$applyAsync();
  }

  toggleNewTicket () {
    if (!this.$scope.showNewTicket.open) {
      this.closeSecondaryNavbar();
      this.$scope.showNewTicket.open = true;
      this.subscribeToClicks();
    } else {
      this.closeSecondaryNavbar();
    }
  }

  openShortcutModal () {
    const modalConfig = {template: 'blocks/crm/views/shortcut_modal.html'};
    this.AdModal.build(modalConfig);
  }

  navbarToggle () {
    this.navbarExpanded = !this.navbarExpanded;
    this.AdStore.set('navbarExpanded', this.navbarExpanded);
  }


  exportColumnModal () {
    if (this.$scope.canExport) {
      this.$rootScope.$emit('modalNewColumn', 'Export', {});
    }
  }

  newColumnModal () {
    this.$rootScope.$emit('modalNewColumn', 'Add', {});
  }

  newCaseModal () {
    this.$rootScope.$emit('modalNewCaseOpen', null, null);
  }

  $onInit () {
    this.$scope.handleExternalClicks = params => {
      const title = params !== 'Blog' ? this.$filter('translate')(params) : params;
      this.FireTagService.setEvent({
        name: 'external_links',
        params: {title}
      });
    };

    this.StylesService.getStyles().then(styles => {
      this.styles = styles
    })

    this.subscribeToLayoutchanges()
    this.$scope.colorScheme = this.$rootScope.colorScheme;
  }

  subscribeToLayoutchanges() {
    this.dividedViewSubscription = this.ColumnsService.dividedView.subscribe(
      isDividedView => {
        this.$scope.isDividedView = isDividedView;
        this.$scope.selectedTicketView = isDividedView ? 'divided' : 'expanded';
      }
    )
  }

  setDividedView () {
    this.ColumnsService.setDividedView()
    this.FireTagService.setEvent({
      name: "event_change_view",
      params: {
      view: "divided"
      }              
    })
  }

  initCannyChangelog () {
    Canny('initChangelog', {
      appID: '5ef55f330172f5179f92acc5',
      position: 'top',
      align: 'left'
    });
  }

  setExpandedView () {
    this.ColumnsService.setExpandedView()
    this.FireTagService.setEvent({
      name: "event_change_view",
      params: {
      view:"expanded" ,
      }              
    })
  }

  getUrl () {
    const token = this.AdRequest.getToken();
    const url = `${this.FRONTEND_VUE_URL}/chatbots/?authToken=${token}`
    return url;
  }

  subscribeToClicks() {
    document.onclick = (e) => {
      const secondarySideBar = document.getElementById('ads-secondary-menu-sidebar-settings');
      const sideBar = secondarySideBar.parentElement;
      if (!secondarySideBar.contains(e.target) && !sideBar.contains(e.target)) {
        this.closeSecondaryNavbar();
        this.$scope.$apply();
      }
    }
  }

  getSearchCaseByIdInput() {
    return document.getElementById('searchCasebyIdInput');
  }

  focusInput() {
    try {
      const searchCasebyIdInput = this.getSearchCaseByIdInput();
      searchCasebyIdInput.focus();
      searchCasebyIdInput.select();
    } catch (e) {};
  }

  subscribeToKeyDown() {
    document.onkeydown = (e) => {
      switch(e.key) {
        case "Enter":
            this.$rootScope.$emit(
              'searchCasebyIdEnterPressed',
              {caseId: e.target.value}
            );
            break;
        case "i":
            if (e.ctrlKey) {
              e.preventDefault();
              this.toggleSearch();
              this.$scope.$applyAsync();
            }
        case "Escape":
            this.closeSecondaryNavbar();
      }
    };
  }

  /* Private methods */

  private scrollToColumn (columnId, delay) {
    this.$timeout(() => {
      const column = document.getElementById(columnId);
      if (column) {
        column.scrollIntoView(
          { behavior: 'smooth', block: 'nearest', inline: 'start' }
        )
        this.$rootScope.$emit('columnCrmSelected', columnId);
      }
    }, delay);
  }

  init() {
    this.$scope.loader = {show: true};
    this.navigationColumns = [];

    this.$scope.unreadCases = 0;
    this.$scope.columnCount = 0;

    this.setListeners();
  }

  setSectionListeners() {
    this.toDestroy.onCRMInit = this.$rootScope.$on('CRM_GOT_INITIAL_DATA', () => {
      Promise.all([
        this.VersionService.getVersion(),
        this.UserService.getProfile(),
        this.EstablishmentService.getEstablishment()
      ]).then(([version, user, establishment]) => {
          this.$scope.version = version
          this.$scope.loader = {show: false}
          this.$scope.sidebar = 'crm'
          const {
            isAnalystOrAdmin,
            isSupervisor,
            isAdmin,
            isCampaignManager,
            profile: {enable_edit_columns}
          } = user
          this.$scope.canEditColumns = enable_edit_columns
          this.$scope.canExport = isAnalystOrAdmin || isSupervisor
          this.$scope.features = establishment.features,
          this.$scope.isAdmin = isAdmin
          this.$scope.isCampaignManager = isCampaignManager
        }
      )
    })

    this.toDestroy.onAnalyticsInit = this.$rootScope.$on('ANALYTICS_GOT_INITIAL_DATA', (_, data) => {
      Promise.all([
        this.VersionService.getVersion(),
        this.UserService.getProfile()
      ]).then(([version, user]) => {
        this.$scope.version = version
        this.$scope.loader = {show: false}
        const {isAnalyst, isAdmin, isSupervisor, isCampaignManager} = user;
        this.$scope.isAnalyst = isAnalyst;
        this.$scope.isAdmin = isAdmin;
        this.$scope.isSupervisor = isSupervisor;
        this.$scope.isCampaignManager = isCampaignManager;
        this.$scope.features = data.features;
        this.$scope.showEPA = this.$scope.features.satisfaction_module;
        this.$scope.aderesoAIenabled = this.$scope.features.adereso_ai;
        this.$scope.analyticTab = data.analyticTab;
        this.$scope.sidebar = 'analytics';
      })
    })

    this.toDestroy.onAdminInit = this.$rootScope.$on('ADMIN_GOT_INITIAL_DATA', (_, data) => {
      Promise.all([
        this.VersionService.getVersion(),
        this.UserService.getProfile()
      ]).then(([version, user]) => {
        this.$scope.version = version
        this.$scope.loader = {show: false}
        const {isAnalyst, isAdmin, isSupervisorNotAdmin, isSupervisor, isCampaignManager} = user;
        this.$scope.isSupervisor = isSupervisor;
        this.$scope.isAnalyst = isAnalyst;
        this.$scope.isAdmin = isAdmin;
        this.$scope.isCampaignManager = isCampaignManager;
        this.$scope.isSupervisorNotAdmin = isSupervisorNotAdmin;
        this.$scope.features = data.features;
        // Showing by default, since the page will display the contact information
        // if the feature flag is disabled.
        this.$scope.showEPA = true;
        this.$scope.aderesoAIenabled = this.$scope.features.adereso_ai;
        this.$scope.adminTab = data.adminTab;
        this.$scope.notifications = data.notifications;
        this.$scope.trialEnding = data.trialEnding;
        this.$scope.sidebar = 'admin';
      })
    })

    this.toDestroy.onSettingsInit = this.$rootScope.$on('SETTINGS_GOT_INITIAL_DATA', (_, data) => {
      this.VersionService.getVersion().then(version => {
        this.$scope.version = version
        this.$scope.loader = {show: false}
        this.$scope.settingsTab = data.settingsTab
        this.$scope.sidebar = 'settings'
      })
    })
  }

  setListeners() {

    document.onkeydown = this.defaultKeyDownEventsHandler;

    this.setSectionListeners();

    this.toDestroy.onCloseBlock = this.$scope.$on('closeBlock', () => {
      this.$scope.toggleNewTicket();
    });

    this.toDestroy.onColsUpdate = this.$rootScope.$on('columnsUpdate', (signal, columns) => {
      this.navigationColumns = columns;
    });

    this.toDestroy.onColBuffLen = this.$rootScope.$on('columnUpdateBufferLength', (signal, columnId, count) => {
      let the_column = this.navigationColumns[columnId];
      if (the_column) {
        this.$scope.unreadCases -= the_column.count;
        this.navigationColumns[columnId].count = count;
        this.$scope.unreadCases += count;
      }
    })

    this.toDestroy.onColNameUpd = this.$rootScope.$on('columnNameUpdated', (signal, columnId, name) => {
      let the_column = this.navigationColumns[columnId];
      if (the_column) {
        this.navigationColumns[columnId].name = name;
      }
    });

    this.toDestroy.onDelCol = this.$rootScope.$on('deleteColumn', (signal, column) => {
      const columnId = column.resource_id;
      delete this.navigationColumns[columnId];
    });

    this.toDestroy.onColUpdtOrd = this.$rootScope.$on('columnUpdateOrder', (signal, newOrders) => {
      for (let columnId in newOrders) {
        let the_column = this.navigationColumns[columnId];
        if (the_column) {
          this.navigationColumns[columnId].order = newOrders[columnId];
        }
      }
    });

    this.toDestroy.onColUpdt = this.$rootScope.$on('columnUpdate', (signal, column) => {
      const columnId = column.resource_id;
      let the_column = this.navigationColumns[columnId];
      if (the_column) {
        this.$scope.unreadCases -= the_column.count;
        this.navigationColumns[columnId] = column;
      }
    });

    this.$scope.$on('$destroy', () => {
      this.dividedViewSubscription.unsubscribe()
      for (const [key, destroyMethod] of Object.entries(this.toDestroy)) {
          if (destroyMethod) {
              destroyMethod();
          }
      }
    });
  }

  constructor(
    $scope,
    $rootScope,
    $timeout,
    UserService: UserService,
    StylesService,
    AdStore,
    ColumnsService: ColumnsService,
    CaseDetailManager,
    AdModal,
    $filter,
    EstablishmentService: EstablishmentService,
    FireTagService,
    VersionService,
    AdRequest: AdRequestService,
    FRONTEND_VUE_URL
  ) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.UserService = UserService;
    this.StylesService = StylesService;
    this.AdStore = AdStore;
    this.ColumnsService = ColumnsService;
    this.CaseDetailManager = CaseDetailManager;
    this.AdModal = AdModal;
    this.$filter = $filter;
    this.EstablishmentService = EstablishmentService;
    this.FireTagService = FireTagService;
    this.VersionService = VersionService;
    this.AdRequest = AdRequest;
    this.FRONTEND_VUE_URL = FRONTEND_VUE_URL

    this.init();

    $scope.handleExternalClicks = params => {
      const title = params !== 'Blog' ? $filter('translate')(params) : params;
      FireTagService.setEvent({
        name: 'external_links',
        params: {title}
      });
    };

    $scope.handleCannyClick = () => {
      UserService.getProfile().then(user => {
        const url = `https://adereso.canny.io/helpdesk?ssoToken=${user.canny_token}`
        window.open(url, '_blank')
      })
    }

    /* Navigation Bar */
    $scope.selectedTicketView = null;
    AdStore.get('navbarExpanded').then( (navbarExpanded) => {
      if (navbarExpanded) {
        this.navbarExpanded = navbarExpanded;
      } else {
        this.navbarExpanded = false;
      }
    });

    $scope.showLateralColumns = {open: false};
    $scope.showSettings = {open: false};
    $scope.showSearch = {open: false};
    $scope.showNewTicket = {open: false};
    $scope.showHelp = {open: false};

    $rootScope.$on('colorSchemeChanged', (_, scheme) => {
      $scope.colorScheme = scheme;
    });

   $scope.selectColumn = (columnId) => {
      let isTicketOpen = document.getElementsByClassName("case-detail-header-info").length;
      this.scrollToColumn(columnId, 100);
      $scope.showLateralColumns.open = false;
      if ($scope.isDividedView && isTicketOpen) {
        $rootScope.$emit('column:select', columnId);
      }
    };

    $scope.selectedTicketViewExpand = () => this.setExpandedView();

    $scope.selectedTicketViewDivide = () => this.setDividedView();

    $scope.initCannyChangelog = () => this.initCannyChangelog();

    $rootScope.$emit('SIDEBAR_READY');
  }
}

adSidebar.$inject = [];
function adSidebar() {
  return {
    scope : {},
    templateUrl: 'blocks/global/views/ad_sidebar.html',
    restrict: 'E',
    controller: AdSidebarCtrl,
    controllerAs: "vm"
  };
}

angular
  .module('postCenterWebClientApp')
  .directive('adSidebar', adSidebar);