(module => {
  'use strict';
  module.controller('massCloseTicketsCtrl', massCloseTicketsCtrl);
  massCloseTicketsCtrl.$inject = [
    '$scope',
    'FRONTEND_VUE_URL',
    '$sce',
    'CloseTicketsService',
    '$rootScope',
    '$filter',
    'AdAvailableSn',
    '$uibModal',
    'UsersService',
    'AdRequest'
  ];
  function massCloseTicketsCtrl(
    $scope,
    FRONTEND_VUE_URL,
    $sce,
    CloseTicketsService,
    $rootScope,
    $filter,
    AdAvailableSn,
    $uibModal,
    UsersService,
    AdRequest: AdRequestService
  ) {
    const vm = this;
    $scope.loading = false;
    vm.$onInit = () => {
      const {users} = $rootScope;

      $scope.iFrameUrl = function() {
        const token = AdRequest.getToken();
        const url = `${FRONTEND_VUE_URL}/frames/mass_close_action?authToken=${token}`;
        return $sce.trustAsResourceUrl(url);
      }

      $scope.activityLog = [];
      $scope.pagination = {
        pageNumber: 1
      };
      if (!users) {
        _getUsers();
      } else {
        vm.users = users;
      }
      vm.getCloseTicketsHistory();
      $scope.loading = true;
      const pageNumberWatcherOff = $scope.$watch('pagination.pageNumber', (newValue, oldValue) => {
        if (oldValue !== newValue) {
          newValue = newValue - 1;
          vm.getCloseTicketsHistory(newValue);
        }
      });
      $scope.$on('$destroy', () => {
        pageNumberWatcherOff();
      });
    };
    vm.translate = string => $filter('translate')(string);

    vm.formatDate = date => moment(date).format('DD/MM/YYYY, h:mm:ss a');
    vm.formatCalendarDate = date => moment(date).calendar();

    const _getUsers = () => {
      UsersService.getUsers().then(_users => {
        vm.users = _users;
      });
    };
    vm.getCloseTicketsHistory = (page = 0) => {
      $scope.loading = true;

      CloseTicketsService.getCloseTicketsHistory(page).then(response => {
        $scope.loading = true;
        const {list = [], elements, page_size: pageSize} = response;

        $scope.activityLog = list.map(val => {
          return _parseLogEvent(val);
        });
        $scope.pagination.totalItems = elements;
        $scope.pagination.pageSize = pageSize;
        $scope.loading = false;
      });
    };
    const _parseLogEvent = event => {
      const created = vm.formatDate(event.created * 1000);
      const startTooltip = vm.formatCalendarDate(event.created * 1000);
      const author = event.author.name;
      const params = {
        author
      };
      if ('tickets' in event.extra_data) {
        const {tickets} = event.extra_data;
        params.modal = {
          title: vm.translate('MASSIVE_ACTION_LOG_MASSIVE_CLOSE_LIST_TITLE'),
          body: tickets,
          size: 'md'
        };
      }
      if ('filters' in event.extra_data) {
        const {sn, from, until, filters} = event.extra_data;
        const paramsBody = {
          ...filters,
          snName: AdAvailableSn.getTitle(sn),
          from: vm.formatDate(from),
          until: vm.formatDate(until)
        };
        const modalBody = vm.translate(
          'MASSIVE_ACTION_LOG_MASSIVE_CLOSE_FILTERS_BODY',
          paramsBody
        );
        params.modal = {
          title: vm.translate('MASSIVE_ACTION_LOG_MASSIVE_CLOSE_FILTERS_TITLE'),
          body: modalBody,
          size: 'md'
        };
      }
      if (event.extra_data.reason === 'MASSIVE_CLOSE_UNASSIGNED'){
        const {message} = event.extra_data;
        const modalBody = message ? vm.translate('MASSIVE_ACTION_LOG_MASSIVE_CLOSE_UNASSIGNED_BODY_WITH_MESSAGE') + message : vm.translate('MASSIVE_ACTION_LOG_MASSIVE_CLOSE_UNASSIGNED_BODY_WITHOUT_MESSAGE')
        params.modal = {
          title: vm.translate('MASSIVE_ACTION_LOG_MASSIVE_CLOSE_UNASSIGNED_TITLE'),
          body: modalBody,
          size: 'md'
        };
      }
      const reasonText = `MASSIVE_ACTION_LOG_${event.extra_data.reason}`;

      return {
        created,
        startTooltip,
        reasonText,
        params
      };
    };
    $scope.refreshHistory = () => {
      vm.getCloseTicketsHistory();
    };
    $scope.openHelpModal = function() {
      $uibModal.open({
        templateUrl: 'blocks/analytics/views/dashboard_help_modal.html',
        size: 'md',
        controller: ['$scope', '$uibModalInstance', modalController]
      });
      function modalController($scope, $uibModalInstance) {
        $scope.title = 'MASSIVE_ACTION_CLOSE_TICKETS_HELP_TITLE';
        $scope.helpText = vm.translate(
          'MASSIVE_ACTION_CLOSE_TICKETS_HELP_BODY'
        );
        $scope.cancel = function() {
          $uibModalInstance.dismiss('cancel');
        };
      }
    };
  }
})(angular.module('postCenterWebClientApp'));
