const WhatsappUpdatedTemplatesModal = Vue.component('WhatsappUpdatedTemplatesModal', {
  props: {
    open: {type: Boolean, required: true},
    onConfirm: {
      type: Function,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    updatedResults: {
      type: Object,
      required: false,
      default: null
    }
  },
  i18n: {
    messages: {
      es: {
        confirm: 'Aceptar',
        titleSyncedTemplates: 'Plantillas actualizadas',
        subtitleSyncedTemplates: 'Se agregaron y/o actualizaron las siguientes plantillas',
        notSyncedTemplatesForAccount: 'No se actualizaron plantillas para esta cuenta',
        titleNotSyncedTemplates: 'No se actualizó ninguna plantilla',
        subtitleNotSyncedTemplates: 'No se actualizó o agregó ninguna plantilla',
        onlyAderesoCanUpdate: 'En estos momentos solo plantillas HSM de cuentas registradas en Adereso BSP pueden ser sincronizadas por este medio.',
        here: 'aquí',
        helpAccountsProvider: 'Si tu cuenta está registrada con otro proveedor visita nuestro panel de ayuda',
        syncing: "Sincronizando plantillas...",
        titleSyncing: "Sincronizando",
        template: "Plantilla",
        status: "Estado"
      },
      pt: {
        confirm: 'Aceitar',
        titleSyncedTemplates: 'Modelos atualizados',
        subtitleSyncedTemplates: 'Os seguintes modelos foram adicionados e/ou atualizados',
        notSyncedTemplatesForAccount: 'Nenhum modelo foi atualizado para esta conta',
        titleNotSyncedTemplates: 'Nenhum modelo foi atualizado',
        subtitleNotSyncedTemplates: 'Nenhum modelo foi atualizado ou adicionado',
        onlyAderesoCanUpdate: 'No momento, apenas modelos HSM de contas registradas no Adereso BSP podem ser sincronizados por este meio.',
        here: 'aqui',
        helpAccountsProvider: 'Se sua conta estiver registrada com outro provedor, visite nosso painel de ajuda',
        syncing: "Sincronizando modelos...",
        titleSyncing: "Sincronizando",
        template: "Modelo",
        status: "Status"
      },
      en: {
        confirm: 'Confirm',
        titleSyncedTemplates: 'Updated Templates',
        subtitleSyncedTemplates: 'The following templates have been added and/or updated',
        notSyncedTemplatesForAccount: 'No templates were updated for this account',
        titleNotSyncedTemplates: 'No template was updated',
        subtitleNotSyncedTemplates: 'No template was updated or added',
        onlyAderesoCanUpdate: 'At the moment, only HSM templates from accounts registered in Adereso BSP can be synchronized through this method.',
        here: 'here',
        helpAccountsProvider: 'If your account is registered with another provider, visit our help panel',
        syncing: "Synchronizing templates...",
        titleSyncing: "Synchronizing",
        template: "Template",
        status: "Status"
      }
    }
  },
  computed: {
    updatedTemplates() {
      return !!this.updatedResults && this.updatedResults.length > 0
    },
    setLabels() {
      let title = 'titleSyncing'
      if (this.updatedTemplates){
        title = 'titleSyncedTemplates'
      }
      else if (!this.loading) {
        title =  'titleNotSyncedTemplates'
      }
      return {
        confirm: this.$t(this.labels ? this.labels.confirm : 'confirm'),
        title: this.$t(title),
        subtitle: this.$t('subtitleSyncedTemplates'),
      };
    },
  },
  template: `
    <div v-if="open"
         class="position-fixed modal-dialog--container d-flex justify-center align-center">
      <aside class="modal-dialog hsm-dialog">
        <header class="modal-header">
          <h4>{{ setLabels.title }}</h4>
          <p v-if="updatedTemplates && !loading">
            {{ setLabels.subtitle }}
          </p>
        </header>
        <div class="modal-body">
          <div v-if="loading || !Array.isArray(this.updatedResults)">
            {{ $t('syncing') }}
          </div>
          <div v-else-if="updatedResults.length > 0">
            <div v-for="item, idx in updatedResults" :key="idx">
              <h5>{{ item.visual_name }} ({{ item.phone_number }})</h5>

              <BasicTable v-if="!!item.templates && item.templates.length > 0"
                :headings="[$t('template'), $t('status')]"
                :sorterFilter="[]"
                :custom="true"
                :striped="true"
                class="template-message-table">
                <tr v-for="template in item.templates">
                  <td class="template-name">{{template.template_name}}</td>
                  <td class="template-status">{{template.template_status}}</td>
                </tr>
              </BasicTable>

              <div v-else class="no-updates"> {{ $t('notSyncedTemplatesForAccount') }}
              </div>
            </div>
          </div>
          <div v-else>
            <p>
              {{ $t('onlyAderesoCanUpdate') }}
            </p>
            <p>
              {{ $t('helpAccountsProvider') }}
              <a href="https://help.adere.so/bandeja-de-entrada-mes%C3%B3n-de-ayuda/wyPWvKJunzeTwyUof2BLCc/hsm-highly-structured-message-/x9CCTpqXTJoxMSS2iRve8Q" 
                target="_blank" 
                rel="noopener noreferrer">
                {{ $t('here') }}
              </a>.
            </p>
          </div>
        </div>
        <footer class="modal-footer">
          <button
            type="button"
            @click="onConfirm()"
            class="btn ads-button ads-button--primary">
            {{ $t(setLabels.confirm) }}
          </button>
        </footer>
      </aside>
    </div>
  `
});
