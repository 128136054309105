(module => {
  'use strict';
  topBarCtrl.$inject = [
    '$timeout',
    '$scope',
    '$rootScope',
    '$filter',
    'AdNotification',
    'AdPolling',
    'adConstants',
    'EstablishmentService',
    'SocialAccountService',
    'UserService',
    'FireTagService',
    'AuthService',
    'ENV',
    '$location',
    'AdRequest',
    'FRONTEND_VUE_URL',
    'StylesService'
  ];

  function topBarCtrl(
    $timeout,
    $scope,
    $rootScope,
    $filter,
    AdNotification,
    AdPolling,
    adConstants,
    EstablishmentService: EstablishmentService,
    SocialAccountService: SocialAccountService,
    UserService: UserService,
    FireTagService,
    AuthService: AuthService,
    ENV,
    $location,
    AdRequest: AdRequestService,
    FRONTEND_VUE_URL,
    StylesService: StylesService
  ) {

    let currentUser: ExtendedUser | null = null
    const vm = this;
    let features = {
      show_onboarding: true
    };

    $scope.envMode = !ENV;
    $scope.ErrorLevel = adConstants.ErrorLevel;
    $scope.handleExternalClicks = params => {
      const title = params !== 'Blog' ? $filter('translate')(params) : params;
      FireTagService.setEvent({
        name: 'external_links',
        params: {title}
      });
    };

    $scope.handleCannyClick = () => {
      UserService.getProfile().then(user => {
        const url = `https://adereso.canny.io/helpdesk?ssoToken=${user.canny_token}`
        window.open(url, '_blank')
      })
    }

    const {adminTab, analyticTab, settingsTab} = $rootScope;
    $scope.adminTab = adminTab;
    $scope.analyticTab = analyticTab;
    $scope.settingsTab = settingsTab;
    
    const url = $location.url();
    $scope.contactAgenda = url.includes(AppState.CONTACTS_STATE);

    $scope.inCRM = !(adminTab.selected || analyticTab.selected || settingsTab.selected || $scope.contactAgenda);

    $rootScope.$watch('adminTab.selected', (newValue, oldValue) => {
      $scope.adminTab.selected = newValue;
      if (newValue !== oldValue) {
        $scope.inCRM = !(newValue || analyticTab.selected || settingsTab.selected || $scope.contactAgenda);
      }
    });

    $scope.$watch('analyticTab.selected', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        $scope.inCRM = !(newValue || adminTab.selected || settingsTab.selected || $scope.contactAgenda);
      }
    });

    $scope.$watch('settingsTab.selected', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        $scope.inCRM = !(newValue || adminTab.selected || analyticTab.selected || $scope.contactAgenda);
      }
    });

    $rootScope.$on('$locationChangeStart', (event, next, current) => {
      const url = $location.url();
      $scope.contactAgenda = url.includes(AppState.CONTACTS_STATE);
      $scope.inCRM = !(adminTab.selected || adminTab.selected || analyticTab.selected || $scope.contactAgenda);
    });

    vm.$onInit = () => {

      $scope.envMode = !ENV;
      $scope.ErrorLevel = adConstants.ErrorLevel;
      $scope.handleExternalClicks = params => {
        const title = params !== 'Blog' ? $filter('translate')(params) : params;
        FireTagService.setEvent({
          name: 'external_links',
          params: {title}
        });
      };

      $scope.trialEnding = $scope.config.trialEnding;

    };

    const destroyables = {
      columnToastDestroy: undefined,
      columnAudioDestroy: undefined,
      importantNotifyDestroy: undefined,
      watchMenuDestroy: undefined,
      watchEstablishmentDestroy: undefined,
      billingPolling: undefined,
      topBarBellAudio: undefined,
      topBarBellToast: undefined,
      watchColorScheme: undefined
    };

    $scope.isOnboarded = function () {
      return currentUser?.profile?.onboarding_progress < 0
    };

    Push.Permission.request().catch(() => {
      const message = $filter('translate')(
        'PUSH_NOTIFICATION_PERMISSION_ERROR'
      );
      $timeout(() => {
        AdNotification.notify(message, 'warning');
      }, 1000 * 3);
    });

    $scope.canManageAccount = false
    $scope.logout = function () {
      AuthService.logout()
    }

    $scope.cancelColumnsUpdates = function () {
      $timeout(() => {
        $rootScope.$emit('cancelColumnsUpdates');
      }, 1);
    };

    $scope.closeNotify = function () {
      $scope.notify = false;
    };

    destroyables.watchAccounts = $rootScope.$on(
      'initialData:accountsReady',
      () => {
        SocialAccountService.accountsObservable.subscribe(accounts => {
          $scope.adminNotificationLevel = adConstants.ErrorLevel.NONE;
          $scope.adminNotifications = {
            channels: adConstants.ErrorLevel.NONE
          };
          if (
            accounts &&
            accounts.length > 0 &&
            accounts.some(account => !account.isHealthy)
          ) {
            $scope.adminNotifications.channels = Math.max(
              $scope.adminNotifications.channels,
              adConstants.ErrorLevel.WARNING
            );
            $scope.adminNotificationLevel = Math.max(
              $scope.adminNotificationLevel,
              adConstants.ErrorLevel.WARNING
            );
          } else if (!accounts || accounts.length === 0) {
            $scope.adminNotifications.channels = Math.max(
              $scope.adminNotifications.channels,
              adConstants.ErrorLevel.INFO
            );
            $scope.adminNotificationLevel = Math.max(
              $scope.adminNotificationLevel,
              adConstants.ErrorLevel.INFO
            );
          }
        })
      }
    );

    let userSub = UserService.userObservable.subscribe((user: ExtendedUser) => {
      StylesService.getStyles().then(styles => {
        currentUser = user
        $scope.userNotificationLevel = adConstants.ErrorLevel.NONE;
        $scope.userNotifications = {
          profile: adConstants.ErrorLevel.NONE
        }
        if (!user.profile.is_verified) {
          $scope.userNotificationLevel = Math.max(
            $scope.userNotificationLevel,
            adConstants.ErrorLevel.WARNING
          );
          $scope.userNotifications.profile = Math.max(
            $scope.userNotifications.profile,
            adConstants.ErrorLevel.WARNING
          );
        }
        $scope.isSupervisor = user.isSupervisor;
        $scope.isAdmin = user.isAdmin;
        $scope.isAnalyst = user.isAnalyst;
        $scope.isCampaignManager = user.isCampaignManager;
        $scope.canAccessCampaigns = (user.isAnalystOrAdmin || user.isCampaignManager) && styles.campaignsDisabled != "true";
      })
    })

    vm.checkBillStatus = function checkBillStatus(data = {}) {
      const {show_message, message} = data;
      if (show_message) {
        $scope.notifyMessage = message;
        $scope.notify = true;
      } else {
        $scope.notify = false;
      }
    };

    function setBillingStatusPolling() {
      const method = () => EstablishmentService.getBusinessStatus();
      const seconds = 10 * 60;
      const onsuccess = vm.checkBillStatus;
      const onerror = () => {};
      if (destroyables.billingPolling) {
        destroyables.billingPolling.upload(method, seconds, onsuccess, onerror);
      } else {
        destroyables.billingPolling = AdPolling.start(
          method,
          seconds,
          onsuccess,
          onerror
        );
      }
    }


  $scope.getUrl = function () {
    const token = AdRequest.getToken();
    const url = `${FRONTEND_VUE_URL}/chatbots/?authToken=${token}`
    return url;
  }

    destroyables.watchMenuDestroy = $scope.$watch(
      'config.showMenu',
      (newValue, oldValue) => {
        if (newValue === true) {
          $rootScope.$emit('updateCharts');
          $rootScope.$emit('loadNotifications');
        }
      }
    );

    let establishmentSub = EstablishmentService.estObservable.subscribe(establishment => {
      $scope.config.establishment = establishment.name;
      $scope.establishment = establishment;
      if ($scope.establishment.features) {
        features = $scope.establishment.features;
      }
      $scope.features = features;
      $scope.campaignsModuleEnabled = establishment.features.campaigns_module; 
      $scope.showOnboarding = features.show_onboarding;
      // Showing by default, since the page will display the contact information
      // if the feature flag is disabled.
      $scope.showEPA = true;
      $scope.showSatisfactionAnalytics = establishment.features.satisfaction_module;
      $scope.aderesoAIenabled = establishment.features.adereso_ai;
      !establishment.is_corporate && setBillingStatusPolling();
    })

    destroyables.importantNotifyDestroy = $rootScope.$on(
      'importantNotify',
      (_obj, message) => {
        if (message.length > 0) {
          if (message !== $scope.notifyMessage) {
            $scope.notify = true;
            $scope.notifyMessage = message;
          }
        } else {
          $scope.closeNotify();
        }
      }
    );

    let audio = new Audio('sounds/notify-g.wav');
    audio.autoplay = false;
    audio.load();

    const listener = () => {
      const audioContext = new AudioContext();
      audioContext.resume().then(() => {
        autoplay('llegaron notificaciones');
      });
    };
    const autoplay = texto => {
      audio
        .play()
        .then(() => {
          document.body.removeEventListener('mousedown', listener, false);
        })
        .catch(() => {
          //this is a fall back message in case anything wwent wrong
          new Notification('Tickets nuevos', {
            icon: 'favicon.png',
            body: texto,
            timeout: 10000
          });
          document.body.addEventListener('mousedown', listener, {
            passive: false
          });
        });
    };

    destroyables.topBarBellNotifications = $rootScope.$on(
      'topBell:bellNotification',
      (_obj, data) => {
        const {icon, timeout, text: body, topBellAudio, topBellToast} = data;

        const message = `NOTIFICATION_DESKTOP_${body[0].text}`;
        const bodyTranslate = $filter('translate')(message, body[0].params);

        if (topBellAudio === true && !document.hasFocus()) {
          autoplay();
        }
        if (topBellToast === true && !document.hasFocus()) {
          Push.create('Notificacion Campana', {
            body: bodyTranslate,
            icon,
            timeout,
            onClick() {
              window.focus();
              this.close();
            }
          });
        }
      }
    );

    destroyables.columnAudioDestroy = $rootScope.$on(
      'column:audioNotification',
      (obj, {column}) => {
        const {audio} = column;
        if (audio) {
          autoplay('Hay Tickets nuevos o actualizados');
        }
      }
    );

    destroyables.columnToastDestroy = $rootScope.$on(
      'column:toastNotification',
      (obj, data) => {
        const {body, toast, title, timeout} = data;
        const icon = '/favicon.png';

        if (toast === true && !document.hasFocus()) {
          Push.create(title, {
            body,
            icon,
            timeout,
            onClick() {
              window.focus();
              this.close();
            }
          });
        }
      }
    )

    // this ctrl never destroy!
    $scope.$on('$destroy', () => {
      Object.entries(destroyables).forEach(([_key, value]) => {
        if (value) {value();}
      })
      establishmentSub.unsubscribe()
      userSub.unsubscribe()
    })

    destroyables.watchColorScheme = $rootScope.$on('colorSchemeChanged', (_, scheme) => {
      $scope.colorScheme = scheme;
    });
  }

  module.controller('topBarCtrl', topBarCtrl);
})(angular.module('postCenterWebClientApp'));
