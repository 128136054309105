interface UserConnectionStatus {
  custom_online_status: object,
  delta_seconds: number,
  end?: Date,
  last_online_active_time: Date,
  last_online_time: Date,
  start: Date
}

interface UserGroup {
  id: number
  name: string
}

interface AssignationConfig {
  establishment: boolean
  fifo: boolean
}

interface AssignationLimits {
  maximum_total_workload: number
  maximum_unanswered_workload: number
  using_establishment_default: boolean
}

interface Profile {
  assignation_config: AssignationConfig
  assignation_limit: AssignationLimits
  avatar_url: string
  departments: number[]
  email_notifications: boolean
  enable_edit_columns: boolean
  id: number
  integration_data: any
  is_assignable: boolean
  is_bot: boolean
  is_delete: boolean
  is_verified: boolean
  language: string
  onboarding_progress: number
  online_status: string
  phone: string
  position: string
  remaining_days_for_verification: number
  timezone: string
  undo_enabled: boolean
  undo_time: number
  use_firebase_authentication: boolean
}

interface User {
  date_joined: string
  email: string
  first_name: string
  last_name: string
  groups: UserGroup[]
  id: number
  is_active: boolean
  last_login: string
  profile: Profile
}

interface LoggedUser extends User {
  canny_token: string
  state: string
}

interface ExtendedUser extends LoggedUser {
  isAdmin:boolean
  isSupervisor: boolean
  isSupervisorNotAdmin: boolean
  isCm: boolean,
  isAdminOrCm: boolean,
  isAnalyst: boolean,
  isAnalystOrAdmin: boolean,
  isAnalystNotAdmin: boolean,
  isManager: boolean,
  isCampaignManager: boolean
}

interface Cm {
  assignation_config: {establishment: boolean, fifo: boolean}
  assignations: [{id: number, mode: number}]
  avatar?: string
  cmid: number
  departments: number[]
  email: string
  is_assignable: boolean
  last_assignation?: string
  name: string
  online_status: string
  open_count?
  open_count_per_sn?
  unanswered_count?: number
}

interface UserSummary {
  id: string
  full_name: string
}

interface CmSummary { // TODO: clean redundancy with UserSummary!
  cmid: number
  name: string
}

interface UserSummaryForDepartments { // TODO: clean redundancy with UserSummary!
  id: number
  name: string
}

interface OnlineStatus {
  blocks: boolean
  core: boolean
  label: string
  persists: boolean
}

interface OnlineStatusLogEntry {
  cos: OnlineStatus
  delta_seconds: number
  end?: number
  online_status: number
  reason: number
  resource_id: string
  start: string
  user_id: number
}

interface OnlineStatusLogPage {
  page: number
  page_size: number
  pages: number
  elements: number
  list: OnlineStatusLogEntry[]
}

class UserService {

  public currentUserStatus: UserConnectionStatus
  private AdRequest: AdRequestService
  private EstablishmentService: EstablishmentService
  private VersionService: VersionService
  private ENV
  private $rootScope

  private cmsModel: AdModelFetcher<Cm[]>
  private botUsersModel: AdModelFetcher<Cm[]>
  private groupsModel: AdModelFetcher<UserGroup[]>
  private userModel: AdModelFetcher<ExtendedUser>
  public userObservable: rxjs.Observable<ExtendedUser>

  constructor(AdRequest, VersionService, EstablishmentService, ENV, $rootScope) {
    this.AdRequest = AdRequest
    this.VersionService = VersionService
    this.EstablishmentService = EstablishmentService
    this.ENV = ENV
    this.$rootScope = $rootScope

    this.currentUserStatus = {
      custom_online_status: {online_status: 2, label: ''},
      start: new Date(),
      end: new Date(),
      delta_seconds: 0,
      last_online_time: new Date(),
      last_online_active_time: new Date()
    }

    this.cmsModel = new AdModelFetcher<Cm[]>(() =>
      this.AdRequest.get<Cm[]>('/api/v1/cms/')
    )

    this.botUsersModel = new AdModelFetcher<Cm[]>(() =>
      this.AdRequest.get<Cm[]>('/api/v1/cms/bot/')
    )

    this.groupsModel = new AdModelFetcher<UserGroup[]>(() =>
      this.AdRequest.get<UserGroup[]>('/api/v1/groups/')
    )
    
    this.userModel = new AdModelFetcher<ExtendedUser>(() => this.fetchProfile())
    this.userObservable = this.userModel.observable()

    AdRequest.loggedOut.subscribe(() => this.userModel.clear())

    this.userObservable.subscribe((user: ExtendedUser) => {
      moment.locale(user.profile.language)
    })
  }

  getGroups(): Promise<UserGroup[]> {
    return this.groupsModel.get()
  }

  getResumeUsers(): Promise<UserSummary[]> {
    return this.AdRequest.get('/api/v1/resume_users/')
  }

  getAllUsers(): Promise<Cm[]> {
    return Promise.all([
      this.getCms(),
      this.getBotUsers()
    ]).then(([cms, botCms]) => cms.concat(botCms))
  }

  getBotUsers(): Promise<Cm[]> {
    return this.botUsersModel.get()
  }

  getCms(): Promise<Cm[]> {
    return this.cmsModel.get()
  }

  refreshCms(): Promise<Cm[]> {
    return this.cmsModel.refresh()
  }

  getProfile(): Promise<ExtendedUser> {
    return this.userModel.get().then(user => {
      this.$rootScope.user = user
      return user
    })
  }

  fetchProfile(): Promise<ExtendedUser> {
    return this.AdRequest.get<LoggedUser>('/api/v1/user/').then(user => {
      if (!user.is_active || user.profile.is_delete) {
        throw new Error('User deleted or inactive');
      }
      let extendedUser = this.enrichUser(user)
      this.pushIdentifyUserEvent(extendedUser)
      return extendedUser
    }).catch(err => {
      console.error('Fetch profile error', err)
      this.AdRequest.logout()
      throw err
    })
  }

  getChartTeamCMs(): Promise<CmSummary[]> {
    return this.AdRequest.get('/api/v1/cms/charting/')
  }

  getOnlineStatus(page = 0, filters): Promise<OnlineStatusLogPage> {
    if (page === null) {
      page = 0;
    }
    return this.AdRequest.post(`/api/v1/online_status_log/${page}/`, filters)
  }

  getUser(userId) {
    return this.AdRequest.get(`/api/v1/user/${userId}/`)
  }

  getCrmData(caseID, data) {
    return this.AdRequest.post(`/api/v1/user/${caseID}/crm`, data)
  }

  sendCrmDataCustom(caseID, data) {
    return this.AdRequest.put(`api/v1/user/${caseID}/crm`, data);
  }

  getUsers(): Promise<User[]> {
    return this.AdRequest.get('/api/v1/users/')
  }

  addUser(user) {
    return this.AdRequest.post('/api/v1/users/', user);
  }

  editUser(user) {
    return this.AdRequest.put(`/api/v1/user/${user.id}/`, user);
  }

  updateActivityUser() {
    return this.VersionService.getVersion().then(
      v => this.AdRequest.post('/api/v1/user/active/', {version: v})
    )
  }

  setUserStatus(status) {
    this.currentUserStatus = status;
  }

  async fetchUserStatus() {
    try {
      const status: UserConnectionStatus = await this.AdRequest.get('/api/v1/user/status/');
      this.setUserStatus(status);
      return status;
    } catch (error) {
      console.error(error);
      return this.currentUserStatus;
    }
  }

  updateUserStatus({resource_id}) {
    return this.AdRequest.post('/api/v1/user/status/', {
      resource_id
    }).then(status => {
      this.setUserStatus(status);
      return status;
    });
  }

  editUserLanguage(user, language) {
    return this.AdRequest.put('/api/v1/userlanguage/', {user, language});
  }

  searchRelatedUsers(method, user_id) {
    return this.AdRequest.post('/api/v1/search_user/', {method, user_id});
  }

  checkEmailAvailability(email) {
    return this.AdRequest.post('/api/v1/users/email/', {email});
  }

  setEnableBotForUser(user_id, enable) {
    return this.AdRequest.post('/api/v1/botcenter/user/enable/bot/', {
      user_id,
      enable
    });
  }
  getUserByCountryId(countryId) {
    return this.AdRequest.post('/api/v1/user/country_id/', {countryId});
  }

  addCustomData(userID, data, config, fromCaseId) {
    if (fromCaseId) {
      // data = angular.copy(data);
      data.from_case_id = fromCaseId;
    }
    return this.AdRequest.put(`/api/v1/author/${userID}/`, data, config);
  }

  setInfluencer(userID, influencer) {
    return this.AdRequest.put(`/api/v1/author/${userID}/influencer/`, {
      influencer
    });
  }

  deleteUser({id}) {
    return this.AdRequest.delete(`/api/v1/user/${id}/`);
  }

  updateAvatar(url = '') {
    if (!url) {
      return Promise.reject('No image');
    }
    return this.AdRequest.post('/api/v1/user/avatar/update/', {url});
  }

  pushIdentifyUserEvent(user: ExtendedUser) {
    this.EstablishmentService.getEstablishment().then(establishment => {
      let trialDays = establishment.plan.trial_days_remaining
      let inTrial = trialDays != null && trialDays > 0
      window.dataLayer.push({
        event: 'identifyUser',
        userId: this.ENV + '_' + user.id,
        userEmail: user.email,
        userName: user.first_name + ' ' + user.last_name,
        createdAt: new Date(+user.date_joined*1000).toISOString(),
        isAgent: user.isCm,
        isAdmin: user.isAdmin,
        isCampaignManager: user.isCampaignManager,
        isAnalyst: user.isAnalyst,
        isSupervisor: user.isSupervisor,
        companyId: establishment.id,
        companyName: establishment.name,
        inTrial: inTrial
      })
    })
  }

  enrichUser(user: LoggedUser): ExtendedUser {
    const parentGroup = names => {
      return user.groups.some(group => names.includes(group.name))
    }
    const enriched_user = {
      ...user,
      isAdmin: parentGroup(['Administrators']),
      isSupervisor: parentGroup(['Supervisors']),
      isSupervisorNotAdmin: parentGroup(['Supervisors']) && !parentGroup(['Administrators']),
      isCm: parentGroup(['Community Managers']),
      isAdminOrCm: parentGroup(['Administrators', 'Community Managers']),
      isAnalyst: parentGroup(['Analysts']),
      isAnalystOrAdmin: parentGroup(['Analysts', 'Administrators']),
      isAnalystNotAdmin: !parentGroup(['Administrators']) && parentGroup(['Analysts']),
      isManager: parentGroup(['Analysts', 'Administrators', 'Supervisors', 'Campaign Managers']),
      isCampaignManager: parentGroup(['Campaign Managers'])
    }
    return enriched_user
  }
}
angular.module('postCenterWebClientApp').service('UserService', UserService);
